/*rating*/

.rating-eval {
  color: #ab8009;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.8rem;
}

.rating-center {
  justify-content: center;
}
/*  input[type='radio']{
    display:none;
  }*/
.nodisplay {
  display: none;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.comment-mine {
  text-align: center;
  margin: 0 1vh 1vh 1vh;
  position: relative;
}

.textarea-mine {
  align-items: center;
  border-color: #15159a;
  border-width: 0.1rem;
}

.inputstl {
  padding: 9px;

  border: solid 1px #460023;

  outline: 0;

  background: -webkit-gradient(
    linear,
    left top,
    left 25,
    from(#ffffff),
    color-stop(4%, #cef4ff),
    to(#ffffff)
  );

  background: -moz-linear-gradient(top, #ffffff, #cef4ff 1px, #ffffff 25px);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;

  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;

  -webkit-box-shadow: rgba(176, 174, 174, 0.1) 0px 0px 8px;
}

.btn-textarea {
  padding-left: 10vw;
}
.comment-table {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #b0aeae;
  padding: 0 0 0 0;
  font-size: 0.8rem;
}
.accordion-header {
  border-bottom: #2512d4;
  border-bottom-width: 0.1rem;
  border-bottom-style: solid;
}

textarea {
  font-family: inherit;
  resize: none;
}
/*
.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: rgba(0, 149, 255, 0.225);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}


.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #29008a;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

*/
.accordion {
  --bs-accordion-btn-bg: none;
  --bs-accordion-active-bg: none;
  --bs-accordion-active-color: #ab8009;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-padding-x: 0.25rem;
}
-accordion-button {
  border-top: 1px solid;
}
.button-eval {
  border-radius: 50%;
  background: #f5ecc4;
  padding: 5px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.button-eval:hover {
  background: #fadc56;
}
.alert-comment {
  font-size:0.8rem !important;
  font-style:italic ;
  color: #c20202;
}
