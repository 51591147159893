/*** FOOTER ***/

#footer {
  color: #fff;
  font-size: 0.9rem;
  width: 100%;
}

#footer .footer-top {
  background:   #04447d; 
}

#footer h5 {
  font-size: 0.95rem;
  font-weight: bold;
}
/*encabezado del footer*/
#footer .footer-top h5 {
  color: #fff;
  position: relative;
  padding-bottom: 2px;
}

/*links al sitio*/
#footer .footer-top .footer-links {
  white-space: nowrap;
}

#footer .footer-links a {
  color: #ddedfa;
}

#footer a:hover {
  color: #ffffff;
}

/*comentario en el footer*/
#footer .footer-info {
  position: relative;
  padding-bottom: 0.5rem;
}
#footer .footer-top .footer-info p {
  font-size: 0.9rem;
  margin-bottom: 0;
  font-family: fantasy;
  color: #ffffff;
  text-align: justify;
}

#footer .center {
  text-align: center;
}

#footer .footer-top .social-links a {
  display: inline-block;
  font-size: 1.2rem;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  /*margin-right: 4px;*/
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #87c2f6;
  color: #014d8f;
  text-decoration: none;
}

/*
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #41a5ff;
  font-size: 1rem;
   line-height: 1rem;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
*/
#footer .footer-top .social-links ul a:hover {
  color: #41a5ff;
}

#footer .footer-bottom {
 /* background: #013f94;*/
  background: #014886;
}

#footer .copyright {
  text-align: center;
  padding-top: 0.5rem;
}

#footer .credits {
  padding-top: 0.4rem;
  text-align: center;
  font-size: 0.8rem;
  color: #f5b718;
  padding-bottom: 0.5rem;
}

#footer .footer-links-nav {
  margin-bottom: 7px;
}

/*** BACK TO TOP BUTON ***/
.back-to-top {
  visibility: visible;
  z-index: 996;
  background:   #014886;
  /*background: #245497;*/
  margin-top: -2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.1rem;
  transition: all 0.4s;
  padding: 0.2rem;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
}

.back-to-top:hover {
  background: #d7c603;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}
/*** PRE-LOADER **
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: '';
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #3399ff;
  border-top-color: #d2f9eb;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}
*/
