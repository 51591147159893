/*shophome*/
#shophome {
  overflow: hidden;
}
.shophome {
  margin-top: 66px;
  margin-left: 0;
  margin-right: 0;
  background: #fff8d9;
  min-height: 90vh;
}
#shopbottom {
  margin-top: -4rem;
  /* height: 35vh;*/
}
.important-advice {
  width: 80%;
  margin-bottom: 0;
}
.btn-circle {
  margin-left: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-size: 1rem;
  padding-left: 0.5rem;
  font-weight: bold;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
  /*line-height: 1.42857;*/
}

.shophome-img {
  width: 5rem;
  height: 3rem;
 /* z-index: 95 !important;*/
}
@media (max-width: 991px) {
  .shophome,
  .serviceForm {
    margin-top: 66px;
  }
  .shophome {
    width:100%;
  }
  .important-advice {
    width: 90%;
  }
}
.center-class {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttongroup {
  /*background: #e9791a;*/
  background: #0d47a1;
  height: 35px;

  top: 35px;
  z-index: 1020;
}

.animated {
  animation: up-down 3s ease-in-out infinite alternate-reverse both;
}



.trending {
  background: #f4fbfd;
}

.card-mine {
  clear: both;
  content: '';
  height: auto;

  column-gap: 0.5rem;
  border-radius: 5px;
}
.card-mine span i {
  font-size: 2.2rem;
  background: #885c04;
  padding: 10px;
  border-radius: 50px;
  color: #fff;
  font-weight: 400 !important;
}

/**** trending ****/

.service-item {
  cursor: pointer;
}

.service-td {
  background: #f0f2f3;
}
.service-headfoot {
  /* background: #f4e4d5 !important;*/
  background: #fff8d9 !important;
  /*background: #f37600 !important;*/
}
/*
.service-page-headfoot {
  background: #f4e4d5;
  height: 2.5rem;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}
*/
.service-header {
  font-weight: bold;
  color: #cb7805;
  font-size: 0.9rem;
}
.service-title {
  font-weight: bold;
  font-style: normal;
  color: #885c04;
  font-size: 1rem;
}
.service-titleII {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight:normal;
  font-style: normal;
  color: #a4290e;
  font-size: 1rem;
}
.service-title-size {
  font-size: 0.85rem !important;
  font-style: italic !important;
}
.service-titlefront {
  font-weight: bold;
  font-style: normal;
  color: #885c04;
  font-size: 1rem;
}
.serviceform {
  padding-top: 40px;
  background: #ffffff;
  min-height: 90vh;
}
.service-imgfull {
  border-width: 0.05rem;
  border-style: solid;
  border-color: rgb(237, 241, 245);
  border-radius: 0.4rem;
  padding: 0.5rem;
}

.service-text {
  color: #e4a00d;
  font-size: 0.9rem;
  height: auto;
 /* outline: 0;*/
}
.service-showimg {
  width: 14rem;
  /*border-color: #f0f2f3;
  border-style: solid;
  border-radius: 1px;*/
}
.service-bolder {
  color: #b65303;
  font-weight: bold;
  font-size: 0.9rem;
}
.withborder {
  border: #b65303;
  border-radius: 5px;
  border-width: 0.8px;
  border-style: solid;
}
.service-bolderfront {
  color: #b65303;
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 0.2rem;
}
.service-phone {
  font-style: italic;
  color: #535252;
  font-size: 0.9rem;
}
.service-phonemail {
  font-style: italic;
  color: #535252;
  font-size: 0.7rem;
}

.service-err {
  font-style: italic;
  color: #f10101;
  font-size: 0.8rem;
}

.service-img {
  max-width: 30rem;
  max-height: 25rem;
  padding: 0.1rem;
}

.service-city {
  font-size: 0.9rem;
  color: #383838;
}
.service-textfront {
  font-size: 0.8rem;
  color: #383838;
  margin-bottom: 0.2rem;
}
.service-link {
  font-style: italic;
  font-size: 0.8rem;
  /*color:#383838*/
}

.service-footer {
  background: #f7f6f2;
  font-size: 0.8rem;
  color: #383838;
  font-style: italic;
  /* text-align: end;*/
  max-height: 2.5rem;
}

.service-inter {
  font-size: 0.8rem;
  max-height: 2.8rem;
}
.service-button-eval {
  text-align: center;
  font-size: 0.7rem;
  color: #be2705;
}
.service-select {
  border-style: none;
  background: #e6cc9c;
}
.servicein-col {
  width: 50%;
}
/*
 .upd-form {
    border:none;
  }
*/
.btnfooter {
  display: flex;
  /* margin: 0 1rem;*/
}
.imgButton {
  text-align: center;
}
.buttons-bigimage {
  margin-top: -2rem;
  margin-right: 2rem;
  text-align: end;
}

.diapositive {
  /* height: 8vh;*/
  display: flex;
  margin-top: 1rem;
  /*margin-bottom: 0rem;*/
  margin-left: 1rem;
  margin-right: 1rem;
}

.diapo-active {
  /* height: 8vh;*/
  display: flex;
  margin-top: 1rem;
  /*margin-bottom: 0rem;*/
  margin-left: 1rem;
  margin-right: 1rem;
  background: #e8e7e5;
  transform: scale(1.1rem);
}

.diapositive:hover {
  border-color: #a87f05;
  transform: scale(1.1rem);
}

.diapositive:focus {
  border-color: #a87f05;
  transform: scale(1.2rem);
}

/*
.producto__info a:hover {
    color: inherit;
}

.product__info h3 a {
    font-size: 1.1rem;
    color: var(--primary-color);
    font-weight: 600;
    margin-top: 15px;
}

.producto__info span {
    font-size: 0.9rem;
}

.producto__card-bottom .price{
    color: var(--primary-color);
    font-size: 1.3rem;
    font-weight: 500;
}

.product__card-bottom span i {
    font-size: 1.2rem;
    padding: 5px;
}
*/
.shopcarousel-img {
  height: 200px;
  width: 100%;
  padding: 0;
  margin: 0;
  object-fit: cover;
}
.carousel-caption h3 {
  background: none;
}
.carousel-caption {
  /* top: 120px;*/
  color: #fff;
  text-align: left;
  font-size: bold;
  padding: 1.5rem 0 0.5rem 0;
  text-shadow: 2px 2px 2px rgb(125, 74, 2);
  z-index: 91;
  /* text-shadow: -10px -5px 1px #4321bf,   -15px -10px 5px #42dd36, -20px -15px 7px #dd7c31;*/
}
