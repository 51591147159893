/*** AUTHENTICATION ***/
.auth {
  margin-top: 50px;
  font-size: 0.8rem;
  background: #ffffff;
  color: #000000;
  min-height: 90vh;
}
.left-menu {
  max-width: 50%;
}
.auth-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 38px;
  width: 350px;
  height: auto;
  padding: 20px;
}

.auth-card-wide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 38px;
  width: 50%;
  height: auto;
  padding: 20px;
}

.auth-card-header {
  padding: 0.8rem;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}

.auth-registration {
  text-align: center;
}
.auth-card-body {
  text-align: left !important;
  color: #2f5ba2;
}
.auth-label {
  padding: 0.4rem;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: left;
}
.auth-label-mail {
  padding: 0.4rem;
  margin-left: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  color:#856d00;
}

.auth-input {
  font-size: 0.8rem;
}
.auth-advice,
.auth-err,
.auth-advice-yellow {
  text-align: center;
  padding: 0.1rem;
  margin-top: 0.2rem;
  font-weight: lighter;
  font-style: italic;
}

.auth-btnini {
  margin-top: 1rem;
  width: 100%;
}

.remove-underline {
  text-decoration: none;
}

.auth-err {
  color: #e00909;
}
.auth-advice-yellow {
  color: hsl(41, 97%, 50%);
}
.auth-conditions {
  font-size: 0.8rem;
  margin-top: 38px;
}
.auth-options {
  display: flex !important;
  flex-direction: column;
}
.auth-icontext {
  padding: 0.4rem;
  font-size: 0.8rem;
  font-weight: bold;
  position: relative;
}
.icon-inside {
  position: absolute;
  color: purple;
  top: 50%;
  transform: translateY(-50%);
  right: 5%;
}
.otp-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 18px;
  width: 375px;
  height: auto;
  /*padding: 10px;*/
}
.opt-body {
  text-align: center;
}
.otp-number {
  padding: 5px;
  max-width: 6rem;
  background: #2f5ba2;
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
}
.otp-title {
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
}
.opt-title p {
  font-weight: normal;
  font-style: italic;
}
.conditions {
  font-size: 0.95rem;
}
.conditions .advice-title {
  color: #9d5a02;
}
.conditions .advice {
  font-weight: bold;
  color: #856d00;
}

@media screen and (max-width: 991px) {
  .auth {
    height: 80vh;
    margin-top: 110px;
  }
  .auth-card-wide {
    width: 100%;
  }

}

/*.icon-inside {
  position: absolute;
  color: purple;
  top: 43%;
  right: 5%;
}*/

/*

.auth-background {
  padding: 10vh 10vw 0 35vw;
  height: 80vh;
  background: url("../img/home.jpg") no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  align-items: center;
}
.auth-background::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(102, 152, 217, 0.8);
}
.formContainer {
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    width: 500px;
    height: auto;
    padding: 20px;
    border: 3px solid #0b68c5;
    background: rgba(1, 3, 111, 0.8);
    border-radius: 5px;
    color: #fff;
  }
  .formContainer #inputCreatePost {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid grey;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 20px;
  }
  
  .formContainer button {
    margin-top: 15px;
    height: 40px;
    border: none;
    background-color: lightskyblue;
    border-radius: 5px;
    color: white;
  }
  
  .formContainer button:hover {
    cursor: pointer;
    background-color: dodgerblue;
  }
 
  .loginContainer {
    position:absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    width: 400px;
    height: auto;
    padding: 20px;
    border: 3px solid #0b68c5;
    background: rgba(1, 3, 111, 0.8);
    border-radius: 5px;
    color: #fff;
  }
  
  .loginContainer input,
  button {
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 2px solid grey;
    border-radius: 5px;
    padding-left: 10px;
    font-size: 20px;
  }
  
  .loginContainer button {
    background-color: dodgerblue;
    color: white;
  }
  
  .loginContainer input {
    border: 2px solid #0b68c5;
  }
*/
