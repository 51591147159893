.arts {
  margin-top: 85px;
  background: #e9f8f5;
}

.arts .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}

.arts .member .pic {
  border-radius: 4px;
  overflow: hidden;
}

.arts .member img {
  transition: all ease-in-out 0.4s;
}

.arts .member:hover img {
  transform: scale(1.1);
}

.arts .member .member-info {
  position: absolute;
  bottom: -80px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.arts .member h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  color: #01036f;
  position: relative;
  padding-bottom: 10px;
}

.arts .member h4::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #0d12fc;
  bottom: 0;
  left: calc(50% - 25px);
}

.arts .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #01036f;
}

.arts .member .social {
  margin-top: 10px;
}

.arts .member .social a {
  transition: color 0.3s;
  color: #01036f;
}

.arts .member .social a:hover {
  color: #3399ff;
}

.arts .member .social i {
  font-size: 16px;
  margin: 0 2px;
}

@media (max-width: 991px) {
  .arts {
    margin-top: 130px;
  }
}