.aboutus {
  margin-top: 100px;
 /* height: 90%;*/
}

@media (max-width: 991px) {
  .aboutus {
    margin-top: 150px;
    min-height: 75%;
  }
}

.col1-icon {
  border-radius: 50%;
  margin-right: 2px;
  width: 1.5rem;
  height: 1.5rem;
  transition: 0.3s;
  background: #87c2f6;
  color: #014d8f;
  text-decoration: none;
}

.center {
  text-align: center;
}

.social-links ul a:hover {
  color: #41a5ff;
}
