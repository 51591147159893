/*** SITES ***/
.sites {
  margin-top: 66px;
  min-height: 90vh;
}
.sitesin {
  background: #ffffff;
  margin-top: 66px;
}

.sites-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.sites-card {
  transition: all ease-in-out 0.4s;
  width: 18rem;
  height: 16rem;
}
/*
.sites-card img:hover {
  transform: scale(1.01);
}
*/
.sites-cheader {
  padding-bottom: 0;
  color: #0237b2;

  /*background-color: #e9f1fb;*/
}
.sites-cheader h1 {
  font-weight: bold;
  font-size: 0.9rem;
}
.sites-cheader h2 {
  font-size: 0.9rem;
  color: #6e6e70;
}
.sites-cheader p {
  font-style: italic;
  font-size: 0.8rem;
}
.sites-cfooter {
  display: flex;
  justify-content: space-between;
  margin: -25 2 5 2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 1.5rem;
  height: 3rem;
  font-weight: bold;
  color: #0237b2;
  background-color: #b3d6a7a3;
  font-size: 0.9rem;
  top: -25px;
  border-bottom-color: #8ad272fd;
  border-bottom-width: 1;
  border-bottom-style: solid;

}

.sites-pic {
  /* border-radius: 0.3rem;*/
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.sites-label {
  color: #0237b2;
  margin-right: 5px;
  font-weight: bold;
  font-size: 0.9rem;
}
.sites-text {
  color: #0237b2;
  font-size: 0.8rem;
  font-style: italic;
}

.site-social-links a {
  display: inline-block;
  font-size: 1.2rem;
  color: #176df7;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.site-social-links a:hover {
  background: #b6d8f6;
  color: #014d8f;
  text-decoration: none;
}

.ioarrow  {
  display: inline-block;
  line-height: 1;
  background: #f7ecd2;
  padding: 6px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  transition: 0.3s;
}


.ioarrow:hover {
  background: #f8e1aa;
  text-decoration: none;
}

.card-noborder  {
  border:none !important;
  background-color:transparent !important;
}

/*
@media (max-width: 991px) {
  .sites {
    margin-top: 66px;
  }
  .sitesin {
    margin-top: 66px;
  }
}
  */       
/*
.sites .member {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.sites .member .pic {
  border-radius: 0.3rem;
  overflow: hidden;
}

.sites .member img {
  transition: all ease-in-out 0.4s;
}

.sites .member:hover img {
  transform: scale(1.1);
}

.sites .member member-info {
  position: absolute;
  bottom: -10.4vh;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);

  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
}

.sites .member h4 {
  font-weight: 700;
  margin-bottom: 1.2vh;
  font-size: 1rem;
  color: #01036f;
  position: relative;
  padding-bottom: 0.4rem;
}

.sites .member h4::after {
  content: '';
  position: absolute;
  display: block;
  width: 3rem;
  height: 0.08rem;
  background: #0d12fc;
  bottom: 0;
  left: calc(50% - 1.5rem);
}
.sites .member .picsites {
  margin-top: 3vh;
}

.sites .member span {
  font-style: italic;
  display: block;
  font-size: 0.9rem;
  color: #01036f;
}

.sites .member .social {
  margin-top: 1vh;
}

.sites .member .social a {
  transition: color 0.3s;
  color: #01036f;
}

.sites .member .social a:hover {
  color: #3399ff;
}

.sites .member .social i {
  font-size: 1rem;
  margin: 0 2px;
}

.memberinput {
  display: none;
}

label {
  color: grey;
}

.clasificacion {
  direction: rtl;
  unicode-bidi: bidi-override;
}

label:hover,
label:hover ~ label {
  color: #ffa500;
}

input[type='radio']:checked ~ label {
  color: #ffa500;
}
*/
/*** SITESIN ***/
/*
.member-mine {
  text-align: center;
  margin: 1vh 0 1vh 0;
  position: relative;
}

.rating-eval {
  color: rgb(211, 157, 9);
  font-weight: 600;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}
*/


/*

  
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Nombre del Sitio:
                </label>
                <div className="col-sm-1">
                  <input
                    name="sitesName"
                    value={form.sitesName}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Subtitulo del Sitio:
                </label>
                <div className="col-sm-1">
                  <Image
                    src={sp} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <input
                    name="sitesSubt"
                    value={form.sitesSubt}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
                
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  
                </label>
                
                <div className="col-sm-1">
                  <Image
                    src={en} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <input
                    name="sitesSubtEn"
                    value={form.sitesSubtEn}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Descripcion:</label>
                <div className="col-sm-6">
                  <Image
                    src={sp} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <textarea
                    name="sitesText"
                    value={form.sitesText}
                    onChange={handleChange}
                    rows="3"
                    className="form-control"
                  />
                </div>
               
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Descripcion:</label>
                <div className="col-sm-6">
                  <Image
                    src={encodeURI} 
                    alt='Spanish'
                    title='Spanish'
                  />{' '}
                  <textarea
                    name="sitesTextEn"
                    value={form.sitesTextEn}
                    onChange={handleChange}
                    rows="3"
                    className="form-control"
                  />
                </div>
               
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">URL:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesUrl"
                    value={form.sitesUrl}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">
                  Correo Electronico:
                </label>
                <div className="col-sm-6">
                  <input
                    name="sitesMail"
                    value={form.sitesMail}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Facebook:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesFacebook"
                    value={form.sitesFacebook}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Instagram:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesInstagram"
                    value={form.sitesInstagram}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Telegram:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesTelegram"
                    value={form.sitesTelegram}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">X(Twiter):</label>
                <div className="col-sm-6">
                  <input
                    name="sitesX"
                    value={form.sitesX}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Orden:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesOrder"
                    value={form.sitesOrder}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Imagen:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesPic"
                    value={form.sitesPic}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label">Publicado?:</label>
                <div className="col-sm-6">
                  <input
                    name="sitesPublished"
                    value={form.sitesPublished}
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 ">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary mt-2 mb-2"
                  >
                    Actualizar
                    <FaRegCheckCircle />
                  </button>
                  <Link
                    to="/admin/site/all"
                    className="btn btn-danger mt-2 mb-2"
                  >
                    Cancelar
                    <IoCloseCircleOutline />
                  </Link>
                </div>
              </div>
*/
