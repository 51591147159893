/*** APP ***/
/*
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
*/
html,
body {
  font-family: 'Open Sans', sans-serif;
  color: #444444;
  /*background: #e4f4f9;*/
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  margin-top:0;
  margin-right:0;
  margin-bottom:0;
  margin-left:0;
}



.center-abs {
  display: flex;
  align-items: center;
  /*vertical-align: middle;*/
  justify-content: center;
}

li {
  list-style: none;
}
a {
  text-decoration: none;
}
section {
  padding: 5px 0;
  overflow: hidden;
}

.section-bg {
  /*background-color: #e7f1f4;*/
  margin-left: 0;
  margin-right: 0;
  position: absolute;
  z-index: 1;
}

.section-title {
  padding-bottom: 1rem;
}
.section-title h2,
.section-title h1 {
  font-size: 0.9rem;
  font-weight: 700;
  padding: 40 0 0 0;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #aaaaaa;
  /* font-family: 'Poppins', sans-serif;*/
}

/*.section-title h2::after {
  content: "";
  width: 10rem;
  height: 0.1rem;
  display: inline-block;
  background: #307d03;
 
}*/

.swal2-title {
  /* font-family: Arial, Helvetica, sans-serif;*/
  font-size: 1rem !important;
  /*color: green !important;*/
}

.swal2-html-container {
  /* font-family: 'Times New Roman', Times, serif;*/
  font-size: 0.9rem !important;
  color: blue !important;
}

.swal2-confirm {
  background-color: orange !important;
}

/*.swal2-icon {
  width: 10%;
  height: 10%
}*/

.section-title p,
.section-title h3 {
  margin: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  /* text-transform: uppercase;*/
  /*font-family: "Poppins", sans-serif;*/
  /*font-family:'Gill Sans';*/
  color: #010483;
}

/* ANIMATIONS */

/*.animated {
  position: relative;
  z-index: 10;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
*/


@-webkit-keyframes up-down {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-5px);
  }
}



/*
  .animated3 {
   animation: animate-preloader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite ;
   animation-delay: 5s;
  }
  .animated4 {
   animation:animate-preloader 2s cubic-bezier(1, 0, 0, 1) infinite alternate-reverse both ;
  }
  .animated5 {
    animation: up-down 2s ease-in-out infinite;
    animation-delay: 1s;
  }
*/

/*
#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #3399ff;
  border-top-color: #d2f9eb;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}*/

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*** Disable AOS delay on mobile ***/

@media screen and (max-width: 991px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
 


}

  /* para eliminar la visualizacion del scroll

  ::-webkit-scrollbar {
    display: none;
  }
*/

/***ABOUT US - CONTACT ***/

.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #3f43fd;
  float: left;
  width: 44px;
  height: 44px;
  background: #f0f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #010483;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #0205a1;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #3399ff;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type='submit'] {
  background: #3399ff;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.contact .php-email-form button[type='submit']:hover {
  background: #34e5a6;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*toast*/
.notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
}
/*REVISADO*/
.align-center {
  align-items: center;
  text-align: center;
}
.align-end {
  align-items: center;
  text-align: end;
}
.align-start {
  align-items: center;
  text-align: start;
}
.line-error {
  font-weight: normal;
  color: #ed3c0d;
}
.line-error1 {
  font-weight: normal;
  font-size: 0.8rem;
  font-style: italic;
  color: #ed3c0d;
  text-align: center;
}

.icon-inside:hover {
  filter: brightness(1.2);
}

.button-shadow {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.button-shadow:hover {
  box-shadow: none;
  border-style:solid;
  border-width: 1;
  border-color:#a9a9a9;
}
.card-shadow {
 /* box-shadow: 2px 2px 2px 2px rgba(188, 210, 229, 0.858);*/
 box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),0 0 0 2px rgb(255,255,255),0.3em 0.3em 1em rgba(0,0,0,0.3)
}

.card-shadowy {
  box-shadow: 1px 1px 1px 1px rgba(250, 228, 147, 0.901);
}


.card-shadow:hover {
  /*box-shadow: none;
  border-style:solid;
  border-width: 1;
  border-color:#a9a9a9;*/
  box-shadow: 
  0 0 2em #d2f0f7, 
  0 0 0 0.2em #66ccff; 
 
  padding: 0.3em;
}

.card-shadowy:hover {

  box-shadow: 
  0 0 2em rgba(247, 214, 92, 0.901), 
  0 0 0 0.2em  rgba(250, 228, 147, 0.901); 
 
  padding: 0.3em;
}
