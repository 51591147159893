/***ADMIN HEADER CSS***/
/*
.adminheader {

  display: flex;
  justify-content: center;
  background: rgba(150, 207, 242, 0.602);

  font-size: 1rem;
  width: 100%;
  top: 0px;
  margin-top: 38px;

  height: 35px;
  line-height: 35px;

}
*/
.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu {
  list-style: none;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.nav-menu a {
  padding: 0 20px 0 20px;
  color: #0026f8;
}
.nav-menu a:hover,
.nav-menu a:focus {
  /*color:rgba(141, 138, 138);*/
  color: rgba(82, 82, 84, 0.8);
}

/* esto es lo que se va a quedar*/

.container-elements {
  margin-top: 60px;
  padding: 0 1rem 1rem 1rem;
}

.card-column1 {
  color: #0026f8;
  font-weight: bold;
}

@media (max-width: 991px) {
  .container-elements {
    margin-top: 90px;
  }
}

.header-elements {
  margin: 1rem 0 2rem;
  font-weight: 600;
  /*background: rgba(1, 3, 111, 0.8);*/
  border-bottom: #dee2e6;

  color: rgba(1, 3, 111, 0.8);
  text-transform: uppercase;
}
.container-elements h5 {
  font-weight: 600;
}

.input-file {
  content: 'Seleccionar';
}
