/*** AMAZING ***/

.amazing {
  padding: 12vh;
  height: 88vh;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.amazing-item {
  /* margin-bottom: 30px;*/
  width: 33.333%;
}

@media screen and (min-width: 768px) {
  /* 5 columns for larger screens */
  .amazing-item {
    width: 20%;
  }
}

.filters #amazing-filters {
  padding: 0;
  margin: 0 3vw 0 3vw;
  list-style: none;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #222222;
  /* margin-bottom: 5px;*/
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.filters #amazing-filters li:hover,
.filters #amazing-filters li.filter-active {
  color: #fff;
  background: #3498db;
}

.filters #amazing-filters li:last-child {
  margin-right: 0;
}

.amazing .amazing-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(34, 34, 34, 0.6);
}

.amazing .amazing-wrap::before {
  content: '';
  background: rgba(34, 34, 34, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.amazing .amazing-wrap img {
  transition: all ease-in-out 0.3s;
}

.amazing .amazing-wrap .amazing-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.amazing .amazing-wrap .amazing-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.amazing .amazing-wrap .amazing-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.amazing .amazing-wrap .amazing-links {
  text-align: center;
  z-index: 4;
}

.amazing .amazing-wrap .amazing-links a {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.amazing .amazing-wrap .amazing-links a:hover {
  color: #3498db;
}

.amazing .amazing-wrap:hover::before {
  opacity: 1;
}

.amazing .amazing-wrap:hover img {
  transform: scale(1.2);
}

.amazing .amazing-wrap:hover .amazing-info {
  opacity: 1;
}
