/*** HEADER & NAVBAR ***/

.navbar {
  /*background: #003785;*/
  background: #04447d;  
  z-index: 95 !important;
  display: flex;
  height: 50px;
  /*align-content: flex-start;*/
  justify-content: space-between;
  overflow: hidden;
}
.navbar1 {
  /*background: #014098 !important;*/
  background: #04447d;
  z-index: 95 !important;
  display: flex;
  height: 35px !important;
  justify-content: center;
  margin-top: 50px;
  overflow: hidden;
}
.navbar2 {
 /* background: #003785;*/
  background: #014886;
  z-index: 95 !important;
  display: flex;
  height: 45px;
  justify-content: space-around;
  margin-top: 80px;
  overflow: hidden;
}

.navbar3 {
  background: #014098 !important;
  z-index: 95 !important;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  /*  padding-left: 1px;
  padding-right: 1px;*/
  height: 30px;
  overflow: hidden;
}

.navlink {
  font-size: 0.8rem;
  margin-bottom: 3px;
}

.navlink-magnify:hover {
  transform: scale(1.1);
}

.navlink-min {
  /* margin: 1px;*/
  font-size: 0.95rem;
  white-space: nowrap;
  z-index: 95 !important;
}
.icons-link {
  font-size: 0.8rem;
  margin-bottom: 3px;
}
.navbar-text {
  /* display: flex;*/
  /* align-items: end;*/
  background: #04447d;
  height: 50px;
  z-index: 95 !important;
}
.form-search {
  position: relative;
  margin-bottom: 1px;
  margin-right: 5px;
}
.search-form-text {
  font-size: 0.75rem;
  font-style: italic;
  padding-right: 6%;
  
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: #f7de9f !important;
}
.navbar-brand {
 padding-left: 1rem;

}
.icon-inside-search {
  position: absolute;
  color: purple;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  z-index: 999;
}

.nav-link {
  white-space: nowrap;
  font-size: 0.95rem !important;
  color: #ffffff !important;
  padding: 3;
  margin-right: 1rem;
}

.nav-link:hover,
.nav-link:focus {
  color: rgb(215, 229, 245) !important;
}

.navuser {
  color: #ffffff;
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: normal;
  font-style: italic;
  white-space: nowrap;
}

.user-act {
  color: #daa520;
}

.navuser:hover,
.navuser:focus {
  color: #f7de9f;
}

.navicon img {
  color: #daa520;
  padding-left: 0;
  padding-right: 0;
  transition: all ease 200ms;
}
.navicon img:hover {
  color: #eadab1;
  transform: scale(1.5);
}

/* mobile*/

@media (max-width: 991px) {
  .nav-container {
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: auto;
    padding: 20px;
    background: #04447d;
    display: flex;
    flex-direction: column;
    transform: translateX(0);
    transition: 0.4s ease all;
    z-index: 1020 !important;
  }
  .navlink {
    font-size: 0.6rem;
    margin-bottom: 2px;
  }
  .nav-link {
    margin-right: 4px;
  }
  
  .navbar {
    background: #04447d;
    height: 50px;
  }
  .navbar-text {
    background: #04447d;
    height: 30px;
  }
  .form-search {
    width: 90%;
    padding-right: 5px;
    padding: 4px;
  }
  
  .navbar-brand {
    margin-left: 1rem;
    padding: 0;
    font-size: 1rem !important;
    width: 50%;
    margin-right: 0;
    margin-top: -8;
  }

  #navuser {
    padding: 0;
    margin-top: -4 !important;
  }
  .navbar-toggler {
    border: none !important;
    background: none !important;
    margin: -10 0 0 0 !important;
    color: #daa520 !important;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
    /* margin-left: 0;*/
  }

  #navbutton {
    color: #f1f3f6;
  }
}



