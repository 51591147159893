#donation {
    margin-top: 50px;   
}

#donation .card {
    background-color:#bd0003;
    color: #ffffff;
    text-align: justify;
    width: 95%;
    margin: 10;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.card-border {
    padding: 15 15 15 15;
    border-color: #ffffff;
    border-radius: 5px;
    border-style:solid;
    border-width: 3px;
    
}


.donation-back-arrow  {
    display: inline-block;
    line-height: 1;
    background: #bd0003;
    padding: 6px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    transition: 0.3s;
    box-shadow: 1px 1px 1px 1px rgba(176, 175, 173, 0.642);
    
  }
  .donation-back-arrow:hover {
    background: #f08282;
    text-decoration: none;
    border-color: #bd0003;
    box-shadow:none;

  }
  .donation-back-text {
    color: #bd0003;
    font-size: 0.95rem;
    font-style: italic;
  }

  .donation-circle  {
    display: inline-block;
    line-height: 1;
    background: #ba8f02;
    color:#ffffff;
    padding: 6px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 6em;
    height: 6em;
    transition: 0.3s;
    box-shadow: 1px 1px 1px 1px rgba(176, 175, 173, 0.642);
  }

  .donation-others  {
    display: inline-block;
    line-height: 1;
   /* background: #ff0000;*/
    color:#ba8f02;
    padding: 6px 0;
    margin-right: 4px;
    border-radius: 15px;
    border-color:#ba8f02;
    border-width: 1px;
    border-style: solid;
    text-align: center;
    width: 10em;
    height: 4em;
    transition: 0.3s;
    box-shadow: 1px 1px 1px 1px rgba(176, 175, 173, 0.642);
    
  }
  .donation-circle:hover {
    background: #c8a102;
    text-decoration: none;
     box-shadow:none;

  }
  .donation-others:focus,
  .donation-others:hover {
    border-color: #c8a102;
    color:#c8a102;
    text-decoration: none;
     box-shadow:none;

  }
  .comment-table-donor {
    padding: 0 0 0 0;
    font-size: 0.8rem;
  }

@media (max-width: 991px) {
    #donation {
      margin-top: 120px;
    }
  }