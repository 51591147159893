/*** HOME ***/

.home {
  background:#e3f2fd;
  margin-top:66px;
  margin-left: 0;
  margin-right: 0;
  /*position: relative;*/
  z-index: 1;
}

.box-home {
  margin-left: 0;
  margin-right: 0;
  width:100%;
 /* overflow-x: hidden;*/
}
.homesites {
  margin-top: -50px;
  z-index: 5;
}

/**--** HOME CAROUSEL **--**/ 

.home-carousel {
/*margin: 0 auto;*/
  position: relative;
  height: 380px;
  z-index: 2;
  padding:0;
  margin:0;
  width: 100%;
}
.home-img {
  border-bottom-color:#bbdefb;
  border-bottom-width: 1px;
  border-bottom-style: solid;
 /* text-align: center;*/
  object-fit: cover;
  position: relative;
  z-index: 1;
  padding:0;
  margin:0;
  width: 100%;
  height: 380px;
}
.home-end-line {
  background-color: #f39a6d78;
}

.carousel-caption h3 {
  font-size: 0.95rem;
  background: none;
  color: #ffffff;
  text-align:left;
  margin-right:5px;
  font-size: bold;
  padding-left: 1rem;
}
.carousel-caption p {
  font-size: 0.8rem;
  background: none;
  color: #fff;
  text-align: left;
  padding-left: 1rem;
}

.carousel-caption {
  position: absolute;
  left: 10px;
  /*top:10px;*/
  top: 325px;
  width: 100%;
  height: 100px;
  /*padding-left: 1rem;*/
  z-index: 5;
  /*background-color: #286bbd7d;*/

}

.carousel-control-prev,
.carousel-control-next {
  top: 30px;
  color:#286bbd !important;
  width: 3rem;
  z-index: 6;
}
.carousel-control-prev {
  left:20px;
}
.carousel-control-next {
  right: 20px;
}

@media (max-width: 991px) {
  .home-carousel {
    max-height: 250px;
    z-index: 2;
  }
  .home-img {
    height: 250px;
    width: 100%;
    object-fit: fill;
  }
  .carousel-caption {
    top: 200px;
   /* left: 200px;*/
  }
  .carousel-caption h3 {
    font-size: 0.80rem;
  }

  .homesites {
    margin-top: -70px;
  }
}

/**--** LINEBOTTOM **--**/ 
.linebottom-blue {
  background: #286bbd;
  margin-top: 0.4px;
  height: 2px;
}
.linebottom-light {
  /*background: rgba(225, 179, 30, 0.701);*/
  background: rgba(214, 232, 245, 0.769);
  margin-top: 0.1px;
  height: 1.5px;
}
.linebottom-painting {
  background-image: '../img/painting.jpeg';
  background-repeat: no-repeat;
  background-size: cover;
  height: 5px;
}

/*** NEWS AND OTHERS ***/
.news-row1 {
  /* news */
  width:100%;
  height: 30px;
  margin-right:0;
  margin-left:0;
  background-color: #025fb0;  
  text-align: center;
  padding: 3 0 0 0;
}
.news-row1-text {
  color:#ffffff;
  font-size: 0.95rem;
  font-style: italic;
}
.news-services {
  width: 96%; 
  height: 300px;
  border-radius: 5px;
  background:#bfe6f9bb;
  margin: 15 auto;
  color:#1565C0;
  font-weight: bold;
  text-align: left;
  padding-top: 10px;    
}
.news-services-text {
  padding-left: 10px;
  text-shadow: 1px 1px 1px #ffffff;

}
.news-row3 {
  padding-top: 10px;
  margin: 0 auto;
  padding-top:0;

}
.news-row3-begin,
.news-row3-end {
  margin: 0 0 5 0;
  width: 98%;
  height: 80px;  
  border-radius: 10px;
  display: flex;
  justify-content: center;
  cursor:pointer;
}
.news-row3-text {
  /*color:#9c0505;*/
  color:#ffffff;
  text-shadow: 2px 2px 1px #9c0505;
  font-weight:600;
 
  
}
.news-row3-begin {
  background-color: #f1dada;
  box-shadow: 1px 1px 1px 1px #faf4f4db;
}
.news-row3-end {
  background-color: #f7f5bf;
  box-shadow: 1px 1px 1px 1px #fafaf4db;
}

.news-row3-begin:hover {
  background-color: #eaa6a6;
  box-shadow:none;
}

.news-row3-end:hover {
  background-color: #f8f492;
  box-shadow:none;
}

.news-down2{
  /* gif down */
  padding-top: 30px;
  width: 90%;
  height: 200px;
  text-align: center;
}
/*
.news-3 {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  border-width:1px;
  border-style:dashed;
  border-color:#286bbd;
  padding: 5 5 5 5;
}

.news-3:hover {
  border-style: solid;
  box-shadow: 1px 1px 1px 1px rgba(188, 210, 229, 0.858);
}
*/




.news-caro-serv {
  height: 250px;
  width:100%;
  position: relative;
  display:flex;
}
.news-caro-box {
  padding-top:10px;
  height: 250px;
  width:91%;
  margin:auto;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  padding-bottom:10px !important;
  display:flex;
  align-items:center;
  scroll-behavior: smooth;
}
.news-caro-card {
  min-width: 187px;
  max-width: 187px;
  height:100%;
  margin:10px 10px;
  cursor:pointer;
  transition:0.5s ease;
  z-index:2;
}
.news-caro-card img {
  background-size:cover;
}
.news-caro-card:hover {
  transform: scale(1.1);
  z-index:5;
}

.news-caro-left-arrow,
.news-caro-right-arrow {
 /* position:absolute;*/
  border: none;
  background:rgba(0,0,0,0.2);
  font-size: 30px;
  height: 50%;
  top: calc(25%);  
  width: 40px;
  color: #fff;
  cursor:pointer;
  z-index: 3;
  transition: .2s ease all;
 }
 .news-caro-left-arrow {
  position:absolute;
  left:3;
 
 }
 .news-caro-left-arrow:hover,
 .news-caro-right-arrow:hover {
  background:rgba(0,0,0,0.3);
}

 .news-caro-right-arrow {
  position:absolute;
  right:3;
 }
 
 @media (max-width: 800px) {
  .news-carousel {
    color:#ffffff;
    font-size: 0.9rem;
    border-bottom:none;
  }
  .news-services {
    width: 95%; 
    height: 300px;
    margin: 15 auto;
  }
  .news-caro-card {
    margin:10px 9px 0 7px;
  }
  .news-caro-box {
    width:88.5%;
  }

}


/*** THEMES ***/

.themes {
  padding: 0;
  margin:0;
  width: 100%;  
  /*height:180px;*/

  /*overflow: hidden;*/
}

.themes p {
  text-align: center;
}





.themes-card {
  border-color:#1352f1;
  border-radius: 0.2rem;
  margin-right: 1rem;
  height: 180px;
  filter: none;
  transition: all 0.4s ease-in-out;
  box-shadow: 1px 1px 1px 1px rgba(188, 210, 229, 0.858);
}

.painting {
  width: 100%;
  object-fit: cover;
}
.themes-card:hover {
  box-shadow: none;
 /* filter: grayscale(100);*/
}

.themes-card:active {
  box-shadow: none;
  box-shadow: 1px 2px 2px 1px rgba(163, 195, 223, 0.858);
}

/**--** DAILY **--**/ 
.daily {
  height: 100px;
}
.daily .surprise {
  font-size: 1.5rem;
  font-weight: bold;
}
.ext-gift {
 /* text-decoration:none;
  background:none;
  border:none;*/
  padding: 2 0 1 0;
  margin-right: -5;
  margin-left:3;
}

.ext-gift:hover {
 filter:invert();
 }
.tbutton {
  color: #825e01;
  font-style: italic;
  text-decoration:none;
  background:none;
  border:none;
}
.tbutton:hover {
  color: #7a5b19;
  font-style: italic;
  text-decoration:none;
  background:none;
  border:none;
  
}
.tbutton:active{
  color: rgb(244, 243, 239);
  font-style: italic;
  text-decoration:none;
  background:none;
  border:none;
}

/**--** CAROUSEL EXTERNAL SALES **--**/ 

.ext-sales {
  padding-left: 20px;
  padding-right: 20px;
}


.ext-sales .ext-buttons {
  display:flex;
  align-items: center;
  position: relative;
}

.ext-sales .ext-buttons .left-arrow,
.ext-sales .ext-buttons .right-arrow {
  position:absolute;
  border: none;
  background:rgba(0,0,0,0.2);
  font-size: 30px;
  height: 50%;
  top: calc(50%-25%);
  line-height: 40px;
  width: 40px;
  color: #fff;
  cursor:pointer;
  z-index: 500;
  transition: .2s ease all;
 }
.box-onesale {
  border-color:#b80404;
  border-width: 2;
  border-style: solid;
}
 .ext-sales .ext-buttons.left-arrow {
  left:0;
 }
/*
 .ext-img {
  min-width: 100%;
  min-height: 100%;
 }
*/
 .ext-sales .ext-buttons .right-arrow {
  right:3;
 }


 .ext-sales .ext-buttons .left-arrow:hover,
 .ext-sales .ext-buttons .right-arrow:hover {
  background:rgba(0,0,0,0.3);
}



.tit-controls {
  display: flex;
  justify-content:center;
  margin-left: 20px;
}
.ext-indicators {
  text-align: end;
}

.tit-controls h4 {
  color: #ffffff;
  font-weight: bold;
  padding-left: 10px;
}
.tit-controls h4:hover {
  color: #0355b2;
}
.tit-controls  .ext-indicators button {
  background: rgb(208, 194, 194);
  height: 3px;
  cursor:pointer;
  border:none;
  margin-right: 2px;

}

.tit-controls  .ext-indicators button:hover,
.tit-controls  .ext-indicators button.activo {
  background:red;
}
.ext-sales .ext-box {
  width: 100%;
  padding: 10px 0;
  /*margin: 10px auto;*/
  /*overflow:hidden;*/
 
  scroll-behavior: smooth;
}
.ext-sales .ext-box .ext-card-sales {
  display: inline-block;
  
  flex-wrap:nowrap;
  justify-content: center;
  overflow: scroll;
  white-space: nowrap;
  display: flex;
  width: 100%;
}
.ext-sales .ext-box .ext-card-sales .ext-onesale {
  min-width:180px;
  min-height: 160px;
  max-width:180px;
  max-height: 160px;
  /*min-width: 20%;  */
  transition: .3s ease all;
  margin-right: 10px;
}
.ext-sales .ext-box .ext-card-sales .ext-onesale img {
  width: 100%;
  height:100%;
  vertical-align: top;
}
.ext-sales .ext-box .ext-card-sales .ext-onesale:hover {
  transform: scale(1.05);
  transform-origin: center;
}

.ext-price {
  margin-top:-15;
  /*font-family:'Franklin Gothic Medium';*/
  font-weight: bold;
  font-size: 0.70rem ;
  color:#1352f1;
  text-align: center;
}
.ext-title {
  font-size: 0.80rem ;

}

.ext-card {
  border-radius: 0.2rem;
  min-width: 100%;
  min-height: 100%;
  transition: all 0.4s ease-in-out;
  box-shadow: 1px 1px 1px 1px rgba(188, 210, 229, 0.858);
}
/*
@media (max-width: 800px) {
  .ext-sales .ext-box {
    overflow: visible;
  }
  .ext-sales .ext-box .ext-card-sales {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
  }
  .ext-sales .ext-indicators,
  .ext-sales .left-arrow,
  .ext-sales .right-arrow {
    display: none;
  }
}
*/
/*- REACT PLAYER-*/
.player-wrapper{
  position: relative;
  padding-top: 56.25%;
}
.react-player {
  position: absolute;
  top:0;
  left:0;
}
/*- end REACT PLAYER-*/

/**--** SLOGANS **--**/
.box-slogans-services {
  margin: 10 auto;
  padding: 0; 
  width: 90%;
  /*height: 200px;*/
  position:relative;
 /* object-fit: cover;*/
}
.box-slogans-sites {
  margin: 10 auto;
  padding: 0; 
  width: 96%;
  /*height: 200px;*/
  position:relative;
 /* object-fit: cover;*/
}
.slogans {
 /* margin: 15 50 15 50;*/
  padding: 0;
  width:100%;
  height:auto;
  position:relative;
  border-color:#c9cacb;
  border-width: 1;
  border-style: solid;
  object-fit: cover;
}

@media (max-width: 400px) {
  .slogans {
    margin: 5 5 5 5 !important;
  }
}

/**--** MODAL **--**/ 
.modal-header {
  color:#b80404;
  background-color: #e3f2fd;
}
.modal-90 {
  min-width: 90vw;
  min-height:90vh;
}
.modal-body {
font-family: cursive;
color:#b86a04;
align-items: center;
background-color: #e3f2fd;
}
.modal-footer {
color:#b86a04;
font-family: cursive;
font-style: italic;
background-color: #e3f2fd;
}
.modal closeButton {
color:#b86a04;
}
.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/**--** SITES SLOGAN **--**/ 
.sites-slogan{
  height: 200px;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

.slogan-text {
  font-family:cursive;
  font-size: 1.5rem;
  font-weight: bold;
}
/*
@media (max-width: 400px) {
  .box-slogan{
    height: 150px;
  }
  
}
*/
/**--** BACKGROUND AND ANIMATIONS **--**/ 
.gradient-lightblue {
  background: rgb(102, 164, 218);
  background: linear-gradient(180deg, rgb(161, 207, 247) 10%, #ffffff 98%); 
}
.gradient-light {
  background: linear-gradient(45deg, #84b7e4 50%, #ffffff 98%); 
}

.gradient {
   background: linear-gradient(180deg in oklab, #014886 10%, #e4f4f9); 
}

.gradient-inv {
 
  background: linear-gradient(180deg, #ffffff 10%, rgb(135, 194, 246,1)  98%); 
}

.gradient-green {
  background: rgb(34,193,195);
  background: linear-gradient(135deg, rgba(34,193,195,1) 23%, rgba(253,247,45,1) 100%); 
}

.animated {
  padding:0;
  position: relative;
  margin-top:5px;
  margin-bottom:0px;
  animation: up-down 7s ease-in-out infinite alternate-reverse both;
 /* animation-delay: 1s;*/
}
.animated1 {
  padding:0;
  position: relative;
  margin-top:5px;
  margin-bottom:0px;
  animation: up-down 7s ease-in-out infinite alternate-reverse both;
  animation-delay: 2s;
}
.animated2 {
  padding:0;
  position: relative;
  margin-top:5px;
  margin-bottom:0px;
  animation: up-down 10s ease-in-out infinite alternate-reverse both;
  animation-delay: 3s;
}
.animated3 {
  padding:0;
  position: relative;
  margin-top:5px;
  margin-bottom:0px;
  animation: up-down 7s ease-in-out infinite alternate-reverse both;
  animation-delay: 2s;
}
.small-animated {
  margin-top:2px;
  margin-bottom:2px;
  animation: up-down 5s ease-in-out infinite alternate-reverse both;
  animation-delay: 2s;
}

.animated10 {
  position: relative;
  margin-top: 5px;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  animation-delay: 4s;
}
.animated20 {
  margin-top: 5px;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  animation-delay: 2s;
}
.animated30 {
  position: relative;
  top: -30px;
  z-index: 2;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  animation-delay: 2s;
}
.animated40 {
  position: relative;
  top: -30px;
  z-index: 2;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  animation-delay: 1.5s;
}
.animated5 {
  position: relative;
  top: -30px;
  z-index: 2;
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
  animation-delay: 2.5s;
}
/*
degradado buenisimo
.sites-slogan {
  background: rgb(34,193,195);
  background: linear-gradient(135deg, rgba(34,193,195,1) 23%, rgba(253,247,45,1) 100%); 

}
*/
/**--** END OF BACKGROUND AND ANIMATIONS **--**/ 





/*
@media (max-width: 575px) {
  .themes img {
    width: 30%;
  }
}
*/
/*** FROMMETOYOU  ----- eliminar ***/

.fmty {
  background: rgb(118, 36, 8);
  padding: 0;
  height: 170px;
}

.fmty row {
  margin: 0 auto;
  align-items: center;
  padding: 0 0.5rem 0 0.5rem;
  /*  min-width: 70vw;*/
  /* max-width: 70vw;*/
}
.frommetoyou carousel-indicators {
  display: none;
}
.fmty img {
  height: 200px;
  /* min-width: 140px;*/
  width: 250px;
  padding-right: 5px;
  filter: none;
  transition: all 0.4s ease-in-out;
  display: inline-block;
}

.fmty img:hover {
  /* filter:sepia();*/
  filter: grayscale(100);
}











/*.sales-card {
  border-color:#1352f1;
  border-radius: 0.2rem;
  margin-right: 1rem;
  height: 180px;
  filter: none;
  transition: all 0.4s ease-in-out;
  box-shadow: 1px 1px 1px 1px rgba(188, 210, 229, 0.858);
}
*/






/*


.carousel-theme {
  height: 200px;
  width: 95%;
  position: relative;
}
.carousel-theme .switchLeft, 
.carousel-theme .switchRight {
    font-weight: bold;
    height: 100%;
    width: 45px;
    line-height:200px;
    font-size: 25px;
    text-align: center ;
    background-color: yellow;
    font-family: sans-serif;
    top: 0;
    z-index: 3; 
}
.carousel-theme .switchLeft {
  position:absolute;
  left: -50px;
}
.carousel-theme .switchRight {
  position:absolute;
  right: -50px;
}


.carousel-theme-box {
    height: 200px;
    width: auto;
    overflow: scroll;
    white-space: nowrap;
    display:inline-block;
    text-align: center;
    padding-bottom: 10px !important;
    display: flex;
    align-items: center;
}
.carousel-theme-img {
    min-width: 140px;
    max-width: 140px;
    height:140px;
    background-size: cover;
    background-color:yellow;
    margin: 5px 10px;
    cursor: pointer;
    transition: 0.5s ease;
    z-index:2;
}
.carousel-theme-img:hover {
  transform: scale(1.4);
  z-index: 5;
}

.themes img {
  width: 7rem;
  height:7rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.themes img:hover { 
  filter: grayscale(100);
}
.themes img:active { 
  filter: none;
}

.themes-price {
  margin-top:-15;
  font-family:'Franklin Gothic Medium';
  font-weight: bold;
  font-size: 0.95rem ;
  color:#1352f1;
  text-align: center;
}



.themes-col {
  display: flex;
  justify-content: center;
  position:relative;
}
.themes-text {
  font-size: 1.3rem;
  font-weight: bold;
  padding-left: 2rem;
}
*/

/*
import React, { useState, useEffect, useContext } from 'react'
import LanguageContext from '../context/LanguageContext'
// import carousel for header section
import { motion } from 'framer-motion'
import { Carousel, Container, Row, Image, Col, Modal, Button } from 'react-bootstrap'
import axios from 'axios'
import { Link } from 'react-router-dom'
// import for themes section
import 'aos/dist/aos.css'
import '../content/home.css'
import siteSlogan from '../img/home/sites.jpg'
import siteSloganEn from '../img/home/sitesen.jpg'
import servSlogan from '../img/home/services.jpg'
import servSloganEn from '../img/home/servicesen.jpg'


const URI = `${process.env.REACT_APP_BACKEND}`



const Home = () => {
  const { texts} = useContext(LanguageContext)
  const [carousel, setCarousel] = useState([])
  const [theme, setTheme] = useState([])
  const [diary, setDiary] = useState([])

  let idiom = texts.idiom === 'es' ? true : false

  const handleSelect = (type) => {
    switch (type) {
      case 1:
        return diary[0].talk
      case 2:
        return diary[0].poem
      case 3:
        return diary[0].pict
      case 4:
        return diary[0].video
      case 5:
        return diary[0].cuba
      default:
        return diary[0].talk
    }
  }

  const getCarouselById = async () => {
    const res = await axios.get(URI + 'carousel/caroId/1')
    setCarousel(res.data)
  }
   
  useEffect(() => {
    getCarouselById()
  }, [])

  const getThemeByActive = async () => {
    const res = await axios.get(URI + 'theme/active/1')
    setTheme(res.data)
  }

  const getDiaryByActive = async () => {
    const res = await axios.get(URI + 'diary/active/1')
    setDiary(res.data)
    console.log(res.data)
  }

  useEffect(() => {
    getThemeByActive()
    getDiaryByActive()
  }, [])

  //Modal
  const [show, setShow] = useState(false);
  const [showType, setShowType] = useState(1);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = (type) =>{
    setShow(true);
    setShowType(type);
  }
  
  return (

      <Container fluid>
        <Row>
        <div class="carousel-corner"></div>
          <Carousel fade indicators={false} className="home-carousel">
            
            {carousel.map((caro) => (
              <Carousel.Item key={caro.id}>
                <Link to={caro.link}>
                  <Image
                    src={URI + 'carousel/' + caro.img}
                    className="home-img"
                    title={`${idiom ? caro.imgName : caro.imgNameEn} ${idiom ? caro.imgPlace : caro.imgPlaceEn}`}
                  />
                  <Carousel.Caption>
                    <h3>{idiom ? caro.imgName : caro.imgNameEn}</h3>
                    <p>{idiom ? caro.imgPlace : caro.imgPlaceEn}</p>
                  </Carousel.Caption>
                </Link>
              </Carousel.Item>
            ))}
          </Carousel>        
        </Row>

        <Row className="linebottom-light"></Row>
        <Row className="linebottom-blue"></Row>
        

        <Row >
          <div className='d-flex justify-content-center gradient'>            
              <Button className='tbutton animated' onClick={handleShow(1)}>
                <motion.div whileHover={{ scale: 1.02 }}>
                    <Image src={`${URI}themes/icons8-talk-32.png`} style={{width:'32',height:'32'}} />Frase de Hoy...
                </motion.div>
              </Button>                                
              <Link className='tbutton animated1' to={handleShow(2)}>
                <Image src={`${URI}themes/icons8-poem-32.png`} style={{width:'32',height:'32'}} />Poesía para el día ...
              </Link>
              <Link className='tbutton animated2' to={handleShow(3)}>
                <Image src={`${URI}themes/icons8-picture-32.png`} style={{width:'32',height:'32'}} />Mi foto preferida ...
              </Link>
              <Link className='tbutton animated1' to={handleShow(4)}>
              <Image src={`${URI}themes/icons8-video-32.png`} style={{width:'32',height:'32'}} /> Mi video para ti ...
              </Link>
              <Link className='tbutton animated2' to={handleShow(5)}>
                <Image src={`${URI}themes/icons8-cuba-32.png`} style={{width:'32',height:'32'}} />Un día en la vida de un Cubano! ...
              </Link>
          </div> 
        </Row>
       
        <Row className="linebottom-blue"></Row>
        <Row className="linebottom-light"></Row>
       

        {theme.length > 0 && (
           
          <Row id='themes'
            className="themes"
            style={{background: theme[0].themesBack.substring(0,1)=== '#' ? theme[0].themesBack : `url(${URI}themes/${theme[0].themesBack})`, backgroundRepeat: 'no-repeat'}}
            >           
                <Row >
                    <Col className="themes-text" style={{color: theme[0].themesTextColor}}>
                      {idiom?theme[0].themesText:theme[0].themesTextEn}
                    </Col>
                </Row>
                
                <Row>
                  
                    <Col className="themes-col" >
                          {theme[0].themesImg1 && (
                            <Link
                              to={
                                theme[0].themesUrl1 === null || theme[0].themesUrl1 === ''
                                  ? theme[0].themesUrlExt1
                                  : theme[0].themesUrl1
                              }
                              target={
                                theme[0].themesUrl1 === null || theme[0].themesUrl1 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                             <div>
                              <Image
                                    src={URI + 'themes/' + theme[0].themesImg1}
                                    title={theme[0].themesTitle1}
                              /> 
                             </div>
                             <div className='themes-price'>
                                ${theme[0].themesPrice1}
                             </div>             
                            </Link>
                          )}
                          {theme[0].themesImg2 && (
                            <Link
                              to={
                                theme[0].themesUrl2 === null || theme[0].themesUrl2 === ''
                                  ? theme[0].themesUrlExt2
                                  : theme[0].themesUrl2
                              }
                              target={
                                theme[0].themesUrl2 === null || theme[0].themesUrl2 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              <div>
                                <Image
                                  src={URI + 'themes/' + theme[0].themesImg2}
                                  title={theme[0].themesTitle2}
                                />
                              </div>
                              <div className='themes-price'>
                                ${theme[0].themesPrice2}
                             </div>
                            </Link>
                          )}
                          {theme[0].themesImg3 && (
                            <Link
                              to={
                                theme[0].themesUrl3 === null || theme[0].themesUrl3 === ''
                                  ? theme[0].themesUrlExt3
                                  : theme[0].themesUrl3
                              }
                              target={
                                theme[0].themesUrl3 === null || theme[0].themesUrl3 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              <div>
                                <Image
                                  src={URI + 'themes/' + theme[0].themesImg3}
                                  title={theme[0].themesTitle3}
                                />
                              </div>
                              <div className='themes-price'>
                                ${theme[0].themesPrice3}
                             </div>
                            </Link>
                          )}
                          {theme[0].themesImg4 && (
                            <Link
                              to={
                                theme[0].themesUrl4 === null || theme[0].themesUrl4 === ''
                                  ? theme[0].themesUrlExt4
                                  : theme[0].themesUrl4
                              }
                              target={
                                theme[0].themesUrl4 === null || theme[0].themesUrl4 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              <div>
                              <Image
                                  src={URI + 'themes/' + theme[0].themesImg4}
                                  title={theme[0].themesTitle4}
                                />
                                </div>
                              <div className='themes-price'>
                                {theme[0].themesPrice4}
                             </div>
                            </Link>
                          )}
                      </Col>
              </Row>

          </Row>
        )}

        <Row id = 'About Services'>
          <Col lg={12} className='p-0'>
            <Link to='/shop/shophome/1/0'>
              <Image
                src={idiom ? servSlogan : servSloganEn}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                fluid
              />
            </Link>
          </Col>
        </Row>
        <Row id = 'About Sites'>
          <Col lg={12} className='p-0'>
            <Link to='/sites'>
              <Image
                src={idiom ? siteSlogan : siteSloganEn}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                fluid
              />
            </Link>
          </Col>
        </Row>
        <Modal show={show} onHide={handleClose}      size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  asdsdsdsd           
                </Modal.Title>
                
              </Modal.Header>
              <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
          </Modal>
     </Container>

  )
}

export default Home

/*
 <Modal show={show} onHide={handleClose}      size="lg" centered>
              <Modal.Header closeButton className='modal-header'>
                <Modal.Title>
                {
                  showType == 1 ? 'El Escrito del día ...' :
                   (showType == 2 ? 'Mi sorpresa para hoy! ...' :'Un dia en la vida de un Cubano...')
                }
                
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='modal-body'>
                {
                  showType === 1 ? diary[0].poem :
                   (showType === 2 ? diary[0].pict :diary[0].cuba)
                }
                </Modal.Body>
              <Modal.Footer className='modal-footer'>
                 {diary[0].author}
              </Modal.Footer>
          </Modal>


*/


/*



/*
   <CardGroup>
                    <Col className="themes-col" >
                          {theme[0].themesImg1 && (
                            <Link
                              to={
                                theme[0].themesUrl1 === null || theme[0].themesUrl1 === ''
                                  ? theme[0].themesUrlExt1
                                  : theme[0].themesUrl1
                              }
                              target={
                                theme[0].themesUrl1 === null || theme[0].themesUrl1 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              <Card className="themes-card animated me-2" > 
                                <Card.Img
                                  variant="top"
                                  src={URI + 'themes/' + theme[0].themesImg1}
                                  title={theme[0].themesTitle1}
                                  style={{width:'18rem',height:'150px'}}
                                />
                                
                              </Card>
                            </Link>
                          )}
                          {theme[0].themesImg2 && (
                            <Link
                              to={
                                theme[0].themesUrl2 === null || theme[0].themesUrl2 === ''
                                  ? theme[0].themesUrlExt2
                                  : theme[0].themesUrl2
                              }
                              target={
                                theme[0].themesUrl2 === null || theme[0].themesUrl2 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              <Card className="themes-card animated1 me-2" >
                                <Card.Img
                                  variant="top"
                                  src={URI + 'themes/' + theme[0].themesImg2}
                                  title={theme[0].themesTitle2}
                                  style={{width:'18rem',height:'150px'}}
                                />
                                
                              </Card>
                            </Link>
                          )}
                          {theme[0].themesImg3 && (
                            <Link
                              to={
                                theme[0].themesUrl3 === null || theme[0].themesUrl3 === ''
                                  ? theme[0].themesUrlExt3
                                  : theme[0].themesUrl3
                              }
                              target={
                                theme[0].themesUrl3 === null || theme[0].themesUrl3 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              
                              <Card className="themes-card card-shadow animated2 me-2" >
                                <Card.Img
                                  variant="top"
                                  src={URI + 'themes/' + theme[0].themesImg3}
                                  title={theme[0].themesTitle3}
                                  style={{width:'18rem',height:'150px'}}
                                />
                                
                              </Card>
                            </Link>
                          )}
                          {theme[0].themesImg4 && (
                            <Link
                              to={
                                theme[0].themesUrl4 === null || theme[0].themesUrl4 === ''
                                  ? theme[0].themesUrlExt4
                                  : theme[0].themesUrl4
                              }
                              target={
                                theme[0].themesUrl4 === null || theme[0].themesUrl4 === ''
                                  ? '_blank'
                                  : '_self'
                              }
                            >
                              
                              <Card className="themes-card card-shadow animated3 me-5" style={{width:'20rem',height:'90%'}}>
                                <Card.Img
                                  variant="top"
                                  src={URI + 'themes/' + theme[0].themesImg4}
                                  title={theme[0].themesTitle4}
                                  style={{width:'18rem',height:'80%'}}
                                />
                              
                              </Card>
                            </Link>
                          )}
                      </Col>
              </CardGroup>



              <Carousel indicators={false} className="theme-carousel">
              <Carousel.Item  interval={1000}>
                <Link className='tbutton' to={handleShowTalk}>
                <Image src={`${URI}themes/icons8-talk-32.png`} style={{width:'32',height:'32'}} />Frase de Hoy...
                </Link>                                
                <Link className='tbutton' to={handleShowPoem}>
                  <Image src={`${URI}themes/icons8-poem-32.png`} style={{width:'32',height:'32'}} />Poesía para el día ...
                </Link>
                <Link className='tbutton' to={handleShowPict}>
                  <Image src={`${URI}themes/icons8-picture-32.png`} style={{width:'32',height:'32'}} />Mi foto preferida ...
                </Link>
                <Link className='tbutton' to={handleShowVideo}>
                <Image src={`${URI}themes/icons8-video-32.png`} style={{width:'32',height:'32'}} /> Mi video para ti ...
                </Link>
                <Link className='tbutton' to={handleShowCuba}>
                  <Image src={`${URI}themes/icons8-cuba-32.png`} style={{width:'32',height:'32'}} />Un día en la vida de un Cubano! ...
                </Link>
              </Carousel.Item>
              <Carousel.Item  interval={1000}>
                <Link className='tbutton' to={handleShowTalk}>
                <Image src={`${URI}themes/icons8-talk-32.png`} style={{width:'32',height:'32'}} />Frase de Hoy...
                </Link>                                
                <Link className='tbutton' to={handleShowPoem}>
                  <Image src={`${URI}themes/icons8-poem-32.png`} style={{width:'32',height:'32'}} />Poesía para el día ...
                </Link>
                <Link className='tbutton' to={handleShowPict}>
                  <Image src={`${URI}themes/icons8-picture-32.png`} style={{width:'32',height:'32'}} />Mi foto preferida ...
                </Link>
                <Link className='tbutton' to={handleShowVideo}>
                <Image src={`${URI}themes/icons8-video-32.png`} style={{width:'32',height:'32'}} /> Mi video para ti ...
                </Link>
                <Link className='tbutton' to={handleShowCuba}>
                  <Image src={`${URI}themes/icons8-cuba-32.png`} style={{width:'32',height:'32'}} />Un día en la vida de un Cubano! ...
                </Link>
              </Carousel.Item>
          </Carousel>
*/


/*
<div id="carousel" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <div class="row">
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">1</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">2</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">3</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">4</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">5</div>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="row">
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">6</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">7</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">8</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">9</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">10</div>
        </div>
      </div>
    </div>
    <div class="carousel-item">
      <div class="row">
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">11</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">12</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">13</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">14</div>
        </div>
        <div class="col">
          <div class="bg-secondary py-4 text-white text-center">15</div>
        </div>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
*/
